<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 2C5.77609 2 4.12279 2.68482 2.90381 3.90381C1.68482 5.12279 1 6.77609 1 8.5C1 10.0629 1.57985 11.3864 2.35666 12.505C2.38554 12.5542 2.4185 12.6008 2.4551 12.6442C3.02368 13.4343 3.68537 14.1197 4.29885 14.7131L11.2929 21.7071C11.6834 22.0976 12.3166 22.0976 12.7071 21.7071L19.7035 14.7107C20.3101 14.1162 20.9703 13.4295 21.5395 12.6386C21.5723 12.5992 21.6021 12.5572 21.6285 12.513C22.4108 11.3913 23 10.0646 23 8.5C23 6.77609 22.3152 5.12279 21.0962 3.90381C19.8772 2.68482 18.2239 2 16.5 2C15.5198 2 14.6268 2.14018 13.7532 2.52068C13.1403 2.78762 12.5679 3.15937 12 3.63935C11.4321 3.15937 10.8597 2.78762 10.2468 2.52068C9.37318 2.14018 8.48018 2 7.5 2ZM20.2301 11C20.7065 10.2255 21 9.40509 21 8.5C21 7.30653 20.5259 6.16193 19.682 5.31802C18.8381 4.47411 17.6935 4 16.5 4C15.7202 4 15.1132 4.10982 14.5518 4.35432C13.984 4.60163 13.4001 5.0141 12.7071 5.70711C12.3166 6.09763 11.6834 6.09763 11.2929 5.70711C10.5999 5.0141 10.016 4.60163 9.44819 4.35432C8.88682 4.10982 8.27982 4 7.5 4C6.30653 4 5.16193 4.47411 4.31802 5.31802C3.47411 6.16193 3 7.30653 3 8.5C3 9.4072 3.28971 10.2282 3.76163 11H8.88194L9.10554 10.5528C9.2786 10.2067 9.63581 9.99147 10.0227 10.0003C10.4095 10.009 10.7566 10.2402 10.9138 10.5939L11.7951 12.5769L13.0384 8.22528C13.1547 7.81842 13.5144 7.52877 13.9367 7.502C14.359 7.47524 14.7524 7.71716 14.9191 8.10608L16.1594 11H20.2301ZM5.40893 13C5.5029 13.0939 5.59835 13.1876 5.69502 13.281L5.70711 13.2929L12 19.5858L18.3001 13.2857C18.3971 13.1906 18.493 13.0954 18.5874 13H15.5C15.0999 13 14.7384 12.7616 14.5808 12.3939L14.1888 11.4792L12.9615 15.7747C12.8459 16.1792 12.4895 16.4681 12.0699 16.4976C11.6502 16.527 11.257 16.2906 11.0862 15.9061L9.77714 12.9609C9.68833 12.9865 9.59518 13 9.49997 13H5.40893Z"
    />
  </svg>
</template>
